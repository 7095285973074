import React, { useEffect } from "react";

const SideBar = ({ titles, createNewChat, handleClickHistory, topic }) => {
  const stylesColor = {
    backgroundColor: "#2c3e50",
    padding: "6px",
    borderRadius: "10px",
  };

  const cutText = (text, length = 25) => {
    if (text.length <= length) {
      return text;
    }
    return text.slice(0, length) + "...";
  };

  return (
    <section className="side-bar">
      <button className="create-new-chat" onClick={createNewChat}>
        + New Chat
      </button>
      <ul className="history text-container">
        {titles?.map((title) => (
          <li
            key={title.id}
            onClick={() => handleClickHistory(title.id)}
            style={topic === title.id ? stylesColor : null}
            className="limited-text"
          >
            {cutText(title.title)}
          </li>
        ))}
      </ul>
      <nav>
        <p>Made by MLD</p>
      </nav>
    </section>
  );
};

export default SideBar;
