import React, { useState } from "react";
import moment from "moment";

const Chat = ({
  currentTitle,
  currentChat,
  disabled,
  value,
  setValue,
  getMessages,
  handleEnterPress,
  chatContainerRef,
}) => {
  return (
    <section className="main">
      {!currentTitle && <h1>ChatMLD</h1>}
      <ul className="feed">
        {currentChat?.map((chatMessage, index) => (
          <li
            key={index}
            style={{
              backgroundColor:
                chatMessage.role === "user" ? "#2c3e50" : "#34495e",
            }}
          >
            <p className="role">
              {chatMessage.role} <br />
              <span style={{ fontSize: 9, fontStyle: "italic" }}>
                {moment(chatMessage.createdAt).format("DD/MM/YYYY HH:mm")}
              </span>
            </p>
            <p>{chatMessage.message}</p>
          </li>
        ))}
        <li ref={chatContainerRef}></li>
      </ul>
      <div className="bottom-section">
        <div className="input-container">
          <input
            disabled={disabled}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleEnterPress}
          />
          <div id="submit" onClick={getMessages}>
            ➢
          </div>
        </div>
        <p className="info">
          ChatMLD June 1 Version. Our goal is to make AI systems more natural
          and safe to interact with. Your feedback will help us improve.
        </p>
      </div>
    </section>
  );
};

export default Chat;
