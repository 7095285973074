import { useState, useEffect, useRef } from "react";
import SideBar from "./components/SideBar";
import Chat from "./components/Chat";

const App = () => {
  const [value, setValue] = useState("");
  const [message, setMessage] = useState(null);
  const [currentTitle, setCurrentTitle] = useState(null);
  const [currentChat, setCurrentChat] = useState([]);
  const [titles, setTitles] = useState([]);
  const [topic, setTopic] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const chatContainerRef = useRef(null);

  const createNewChat = () => {
    setMessage(null);
    setTopic("");
    setCurrentTitle(null);
    setCurrentChat(null);
  };

  const getMessages = async () => {
    setDisabled(true);

    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          message: value,
          topicId: topic,
        }),
        mode: "cors",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/topic`,
        options
      );

      if (response.ok) {
        const data = await response.json();

        if (topic.length === 0) {
          const newTitle = { id: data[0].topicId, title: data[0].title };
          setTitles([newTitle, ...titles]);
        }

        setCurrentChat(data);
        setTopic(data[0].topicId);
        setValue("");

        setDisabled(false);
        scrollToBottom();
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTitles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/titles`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTitles(data);
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getHistory = async (topicId) => {
    setTopic(topicId);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}//api/chat/history/` + topicId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setCurrentChat(data);
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  useEffect(() => {
    getTitles();
  }, []);

  const handleClickHistory = (topicId) => {
    getHistory(topicId);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      console.log("ENter");
      getMessages();
    }
  };

  const scrollToBottom = () => {
    chatContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="app">
      <SideBar
        titles={titles}
        createNewChat={createNewChat}
        handleClickHistory={handleClickHistory}
        topic={topic}
      />
      <Chat
        currentTitle={currentTitle}
        currentChat={currentChat}
        disabled={disabled}
        value={value}
        setValue={setValue}
        getMessages={getMessages}
        handleEnterPress={handleEnterPress}
        chatContainerRef={chatContainerRef}
      />
    </div>
  );
};

export default App;
